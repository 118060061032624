import React from 'react'
import ProjectModel from '../model/ProjectModel'
import Typography from '../components/Typography'
import ProjectCard from '../components/ProjectCard'

const Project = () => {
  return (
    <main className=''>
        <div className='p-4 ml-8 mr-8 md:ml-16 md:mr-16 lg:ml-32 lg:mr-32'>
            <div className='w-full flex flex-row justify-center min-h-[20rem]'>
                <div className='flex flex-col w-full'>
                    <Typography variant='heading' className='mb-2'>Project Gallery</Typography>
                    <div className='grid grid-cols-1 xl:grid-cols-2 gap-8'>
                        {ProjectModel.map(project => (
                            <ProjectCard key={project._id} project={project} />
                        ))}
                    </div>
                    <Typography variant='small' className='text-center mt-8'>Thats all for now.</Typography>
                </div>
            </div>
        </div>
    </main>
  )
}

export default Project