import React from 'react'
import Typography from '../components/Typography'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className='flex justify-center items-center min-h-[10rem] mt-32'>
        <div className='flex flex-col justify-center items-center'>
            <img
                src='assets/svg/not_found.svg'
                alt='Not Found'
                className='w-1/2'
            />
            <Typography variant='heading' className='text-center'>Sorry, page isnt available.</Typography>
            <div className='flex flex-row justify-center items-center'>
                <Link to='/'>
                    <Typography variant='button'>Go back</Typography>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default NotFound