import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Typography from '../components/Typography';
import ProjectModel from '../model/ProjectModel';
import { IoArrowBack } from "react-icons/io5";

const ProjectDetail = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const foundProject = ProjectModel.find(proj => proj._id === projectId);
        setProject(foundProject);
    }, [projectId]);

    return (
        <main className=''>
        <div className='p-4 ml-8 mr-8 md:ml-16 md:mr-16 lg:ml-32 lg:mr-32'>
            <div className='w-full flex flex-row justify-center min-h-[20rem]'>
                <div className='flex flex-col w-full'>
                    <Link to='/project' className='w-fit'>
                        <div className='flex items-center gap-1 border-b'>
                            <IoArrowBack />
                            <Typography variant='small'>See more</Typography>
                        </div>
                    </Link>
                    <Typography variant='heading' className='my-8'>Project Detail</Typography>
                    <div className='flex flex-col'>
                        {project && (
                            <>
                                <div className='flex flex-col justify-center items-start gap-4'>
                                    <div className='flex flex-col gap-2 justify-center'>
                                        <Typography variant='subheading'>{project.projectName}</Typography>
                                        <Typography variant='caption'>{project.projectPlatform}, {project.projectDate}</Typography>
                                        <Typography variant='small'>Description:</Typography>
                                        <div className='flex flex-col'>
                                            {project.projectDescription.map((desc, index) => (
                                                <div key={index} className=''>
                                                    <Typography variant='small'>{desc}</Typography>
                                                </div>
                                            ))}
                                        </div>
                                        <div className='flex flex-wrap'>
                                            {project.tags.map((tag, index) => (
                                                <div key={index} className='bg-blue-500 rounded-full px-3 py-1 mr-2 mb-2'>
                                                    <Typography variant='small' className='text-white'>#{tag}</Typography>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='flex flex-wrap justify-center items-center gap-16'>
                                        {project.images.map((image, index) => (
                                                <img
                                                    key={index}
                                                    src={`${process.env.PUBLIC_URL}/${image}`}
                                                    alt={project.projectName}
                                                    className='max-w-[10rem]'
                                                />
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </main>
    );
};

export default ProjectDetail;
