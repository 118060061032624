import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import { Alert } from '@material-tailwind/react';

const MessageForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        subject: '',
        message: ''
    });

    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setFormData({ email: '', subject: '', message: '' });
        
        emailjs.sendForm('service_5q6w3a1', 'template_e1c8plf', e.target, 'ZPCFxDZEyGRoS2mR8')
        .then((result) => {
            setShowAlert(true); 
            setTimeout(() => {
                setShowAlert(false);
            }, 5000); 
        }, (error) => {
            // console.log(error.text);
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 5000);
        });
    };

    return (
        <div className='comfortaa-600'>
            <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email
                    </label>
                    <input
                        type='email'
                        id='email'
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        className='mt-1 p-2 block w-full border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-sm'
                        placeholder='Your e-mail'
                        maxLength={35}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                        Subject
                    </label>
                    <input
                        type='text'
                        id='subject'
                        name='subject'
                        value={formData.subject}
                        onChange={handleChange}
                        className='mt-1 p-2 block w-full border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-sm'
                        placeholder='Subject'
                        maxLength={15}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                        Message
                    </label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        rows="4"
                        className="mt-1 p-2 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-sm"
                        placeholder="Your message"
                        maxLength={300}
                        required
                    ></textarea>
                </div>
                {showAlert && (
                    <Alert color="green" className="my-4">
                        Message sent successfully!
                    </Alert>
                )}
                {showError && (
                    <Alert color='red' className='my-4'>
                        Error sending message! Try Again!
                    </Alert>
                )}
                <div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-[#6B84D1]/70 hover:bg-[#6B84D1] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-2"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
}

export default MessageForm