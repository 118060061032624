import React from 'react'
import Typography from './Typography'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const ProjectCard = ({ project }) => {
    const { projectName, projectPlatform, images } = project;

    const truncateDescription = (description, maxLength) => {
        if(description.length <= maxLength) return description;
        return description.substring(0, maxLength) + '...';
    }

    return (
        <motion.div 
            initial={{ y: 0 }}
            whileHover={{ y: 5 }}
            className='w-full min-h-[10rem] max-h-[20rem] rounded-xl flex flex-row justify-center border transition-shadow duration-200 ease-in-out shadow-lg hover:shadow-xl'
        >
            <div className='w-2/3 flex flex-col p-4 md:p-8 justify-between'>
                <div className='flex flex-col gap-2'>
                    <Link to={`/project/${project._id}`}>
                        <Typography variant='subheading' className=''>{projectName}</Typography>
                    </Link>
                    <Typography variant='small' className=''>Platform: {projectPlatform}</Typography>
                    <div className='flex flex-col'>
                        {project.projectDescription.map((desc, index) => (
                            <div key={index} className=''>
                                <Typography variant='small'>{truncateDescription(desc, 50)}</Typography>
                            </div>
                        ))}
                    </div>
                </div>
                <Link to={`/project/${project._id}`} className='w-fit'>
                    <Typography variant='button'>
                        Overview
                    </Typography>
                </Link>
            </div>
            <div className='w-1/3 flex flex-row justify-end'>
                <img    
                    src={images[0]}
                    alt=''
                    className='max-h-full max-w-full object-contain rounded-xl'
                />
            </div>
        </motion.div>
    )
}

export default ProjectCard