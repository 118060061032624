import React from 'react'
import { Link } from 'react-router-dom'
// import { IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io5'
// import { FaGithub } from 'react-icons/fa'
import Typography from './Typography'

const Footer = () => {
  return (
    <footer className="text-gray-600 body-font border-t mt-8 md:mt-16">
        <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
            <Link to='/'>
                <Typography variant='small'>dimasgusti</Typography>
            </Link>
            <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">© 2023 — Dimas Gusti</p>
            <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start items-center gap-4">
            {/* <Link to='https://instagram.com/dimsgusti'>
                <IoLogoInstagram size={24} />
            </Link>
            <Link to='https://www.linkedin.com/in/dimsgusti'>
                <IoLogoLinkedin size={24} />
            </Link>
            <Link to='https://www.github.com/dimsgusticode'>
                <FaGithub size={24} />
            </Link> */}
            </span>
        </div>
    </footer>
  )
}

export default Footer