const ProjectModel = [
    {
        _id: 'f0299b30',
        projectName: 'Celam',
        projectPlatform: 'Android',
        projectDescription: [
            '• Celam simplifies financial tracking and budgeting, empowering users to take control of their finances effortlessly.',
            '• With a sleek and user-friendly interface, Celam ensures a smooth navigation experience.',
            '• With the power of Firebase, Celam provides real-time data synchronization.',
        ],
        projectDate: 'Dec 2023',
        tags: [
            'Flutter',
            'Firebase',
            'Android'
        ],
        images: [
            'assets/project/celam1.png',
            'assets/project/celam2.png',
            'assets/project/celam3.png',
            'assets/project/celam4.png'
        ]
    },
    {
        _id: '733ba597',
        projectName: 'Appserba',
        projectPlatform: 'iOS',
        projectDescription: [
            '• Appserba is an anything you need for everyday use',
            '• User-friendly interface really helping for the new users!'
        ],
        projectDate: 'Mar 2024',
        tags: [
            'Swift',
            'iOS'
        ],
        images: [
            'assets/project/appserba1.png',
            'assets/project/appserba2.png',
            'assets/project/appserba3.png',
            'assets/project/appserba4.png'
        ]
    },
]

export default ProjectModel