import React from 'react'
import PropTypes from 'prop-types'

const Typography = ({ variant, className, children}) => {
    let typographyClass = '';

    switch(variant){
        case 'title':
            typographyClass = 'text-3xl md:text-4xl lg:text-5xl philosopher-regular';
            break;
        case 'heading':
            typographyClass = 'text-xl md:text-2xl lg:text-3xl comfortaa-600';
            break;
        case 'subheading':
            typographyClass = 'text-md md:text-lg lg:text-xl comfortaa-600';
            break;
        case 'caption':
            typographyClass = 'text-base md:text-md lg:text-lg comfortaa-600';
            break;
        case 'small':
            typographyClass = 'text-xs md:text-sm comfortaa-600';
            break;
        case 'smallthin':
            typographyClass = 'text-xs md:text-base source-code-400';
            break;
        case 'button':
            typographyClass = 'bg-[#6B84D1]/70 hover:bg-[#6B84D1] w-fit text-white p-2 mt-2 text-xs md:text-sm comfortaa-600 rounded-full transition duration-200 ease-in-out shadow-lg hover:shadow-xl';
            break;
        default:
            typographyClass = 'text-base';
    }

    return (
        <div className={`text-[#251e18] ${typographyClass} ${className}`}>
            {children}
        </div>
    );
};

Typography.propTypes = {
    variant: PropTypes.oneOf(['title','heading', 'subheading', 'caption', 'small','smallthin','button']),
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Typography.defaultProps = {
    variant: 'body',
    className: '',
};

export default Typography