import React from 'react'
import Typography from '../components/Typography'
import ProjectCard from '../components/ProjectCard'
import { Link } from 'react-router-dom'
import AboutMe from '../components/AboutMe'
import ProjectModel from '../model/ProjectModel'
import MessageForm from '../components/MessageForm'

const Home = () => {
  return (
    <>
        <main className=''>
            <div className='p-4 ml-8 mr-8 md:ml-16 md:mr-16 lg:ml-32 lg:mr-32'>
                <div className='w-full flex flex-row justify-center items-center min-h-[20rem] border-b'>
                    <div className='flex flex-col'>
                        <Typography variant='title' className='text-center'>Hi. I'm
                            <span className='bg-gradient-to-r from-[#6B84D1] to-[#FF7F50] text-transparent bg-clip-text ml-2'>
                                Dimas Gusti. 
                            </span>
                        </Typography>
                        <Typography variant='smallthin' className='text-center'>Welcome to my digital domain, where lines of code shape my portfolio gallery.</Typography>
                    </div>
                </div>
            </div>
        </main>
        <main className=''>
            <div className='p-4 ml-8 mr-8 md:ml-16 md:mr-16 lg:ml-32 lg:mr-32'>
                <div className='w-full flex flex-row justify-center min-h-[20rem]'>
                    <div className='flex flex-col w-full'>
                        <Typography variant='heading' className='mb-2'>Latest Project</Typography>
                        <div className='grid grid-cols-1 xl:grid-cols-2 gap-8'>
                            {ProjectModel.map(project => (
                                <ProjectCard key={project._id} project={project} />
                            ))}
                        </div>
                        <Link to='/project' className='mt-8 bg-[#6B84D1]/70 hover:bg-[#6B84D1] text-white p-2 text-xs md:text-sm comfortaa-600 transition duration-200 ease-in-out shadow-lg hover:shadow-xl'>
                            <Typography variant='small' className='text-white text-center'>Browse more</Typography>
                        </Link>
                    </div>
                </div>
            </div>
        </main>
        <main className=''>
            <div className='p-4 ml-8 mr-8 md:ml-16 md:mr-16 lg:ml-32 lg:mr-32'>
                <div className='w-full flex flex-row justify-center min-h-[20rem]'>
                    <div className='flex flex-col w-full'>
                        <Typography variant='heading' className='mb-4'>About me</Typography>
                        <AboutMe />
                    </div>
                </div>
            </div>
        </main>
        <main className=''>
            <div className='p-4 ml-8 mr-8 md:ml-16 md:mr-16 lg:ml-32 lg:mr-32'>
                <div className='w-full flex flex-row justify-center min-h-[20rem]'>
                    <div className='flex flex-col w-full'>
                        <Typography variant='heading' className='mb-4'>Message me</Typography>
                        <MessageForm />
                    </div>
                </div>
            </div>
        </main>
        {/* <div className='flex justify-center items-center mt-16'>
                <iframe src="https://www.fiverr.com/gig_widgets?id=U2FsdGVkX1+7zIWD6QIQIX5FAcjnTIfUKry+CvARi888f0ZSDjpg7SJNuyu1U1mK2tSubs8+ODs4QSRwmlTyfW5hGgKs4Vj5pMgemIuPJg6cxc2HNLIvnt+8R/82hKAdhg8kx6pubFzIIpYC5Am5MlUwGlDeZpSLlV82YNEuViyxNOUAhp/1RLpQtT/uq0krwSC/MedYFKVQgLrdF9/oVhL7wifoTBjqQBn3Kd095jKl9oYgJVngxrG8yBNB5+NdjsOy1i8cJbG8MePGEbtqqZCPP233m+K3Ex4oQqtxh1EdHTo450YjZnzMl1+a5LShVuLY9jZ4WxaVo4O7+G7Lt0czJcHkUdeWyLvDv7YZegqx2rvBH4ar8r/eQUa8VRSY5J0Ce5g/eQQi7Af3AJws/Q0K9vq1EaIEM4dSEBXRpj+IdcjfwkwBAhFssKOE3/aYd0HJCwK1Ip39r3cLvYxvpP7GMCJ3pQlMDOe7I5xLlrG9loa+FX1KJx3MavSldboFaim6lNdwPhSb6Wdt6vqLS1hwk7zu9MVs3m9UlIG8rD1sEeVzXWMsPhwIFJ7aJ56NIdTDSYygbc89fHCcLDmLBg==&affiliate_id=979505&strip_google_tagmanager=true" title='Fiverr' loading="lazy" data-with-title="true" class="fiverr_nga_frame" frameborder="0" height="350" width="100%" referrerpolicy="no-referrer-when-downgrade" data-mode="specific_gig" onload=" var frame = this; var script = document.createElement('script'); script.addEventListener('load', function() { window.FW_SDK.register(frame); }); script.setAttribute('src', 'https://www.fiverr.com/gig_widgets/sdk'); document.body.appendChild(script); " ></iframe>
        </div> */}
    </>
  )
}

export default Home