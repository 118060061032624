import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { SpeedInsights } from '@vercel/speed-insights/react'
import { Analytics } from '@vercel/analytics/react'

const App = () => {
  return (
    <>
      <div className='min-h-screen'>
        <Navbar />
        <main>
          <Outlet />
          <SpeedInsights />
          <Analytics />
        </main>
        <div className='sticky top-[100vh]'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default App