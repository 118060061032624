import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Typography from './Typography'

const Navbar = () => {
    return (
    <nav className='p-4 shadow sticky top-0 backdrop-blur z-20'>
        <ul className='flex justify-center md:justify-between items-center gap-10 md:mr-16 md:ml-16 lg:mr-32 lg:ml-32'>
            <div className='gap-4 hidden md:flex'>
                <div>
                    <Link to='/'>
                        <Typography variant='small'>dimasgusti</Typography>
                    </Link>
                </div>
            </div>
            <div className='flex gap-10 items-center'>
                <NavLink exact='true' to='/' activeclassname='active'>
                    <Typography variant='small'>Home</Typography>
                </NavLink>
                <NavLink to='/project' activeclassname='active'>
                    <Typography variant='small'>Project</Typography>
                </NavLink>
            </div>
        </ul>
    </nav>
  )
}

export default Navbar