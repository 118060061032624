import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom'
import Home from './views/Home';
import ProjectDetail from './views/ProjectDetail';
import NotFound from './views/NotFound';
import Project from './views/Project';

const router = createBrowserRouter (
  createRoutesFromElements (
    <Route path='/' element={<App />} >
      <Route index={true} path='/' element={<Home />} />
      <Route path='/project' element={<Project />} />
      <Route path='/project/:projectId' element={<ProjectDetail />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
